<template>
  <iq-main>
    <div class="container">
      <div class="wrapper">
        <md-icon class="md-primary md-size-3x" v-if="status === 'default'"
          >password</md-icon
        >

        <md-icon class="md-primary md-size-3x" v-else-if="status === 'sent'"
          >task_alt</md-icon
        >

        <md-icon class="md-accent md-size-3x" v-else>bug_report</md-icon>

        <div class="md-title">Passwort zurücksetzen</div>

        <div class="md-body-1">
          Bitte geben Sie Ihre E-Mail-Adresse an, damit Sie Ihr Passwort
          zurücksetzen können
        </div>

        <md-field class="w-30">
          <label>E-Mail-Addresse</label>
          <md-input type="email" v-model="email" />
          <span class="md-helper-text"
            >Bitte geben Sie hier die E-Mail-Adresse an, mit welcher Ihr Account
            erstellt wurde.</span
          >
        </md-field>

        <div class="w-30">
          <md-button class="md-primary button-adjuster" @click="recoverPassword"
            >Password zurücksetzen</md-button
          >
        </div>

        <div class="w-30" style="text-align: right; margin-top: 1rem">
          <router-link to="/login">Zurück zum Login!</router-link>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <md-snackbar :md-active.sync="showMsg">{{ msg }}</md-snackbar>
  </iq-main>
</template>

<script>
import axios from "axios";
import Settings from "@/config";

const AUTH_URI = Settings.API_URI_AUTH;

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: null,
      msg: null,
      showMsg: false,
      status: "default",
    };
  },
  methods: {
    recoverPassword() {
      let params = new URLSearchParams();
      params.set("email", this.email);

      axios
        .post(
          `${AUTH_URI}/password/forgot`,
          {},
          {
            params: params,
          }
        )
        .then((response) => {
          this.msg = response.data.msg;
          this.showMsg = true;
          this.email = null;
          this.status = "sent";
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.email = null;
            this.msg = error.response.data.detail;
          } else {
            this.msg = "Request could not be processed.";
          }
          this.showMsg = true;
          this.status = "error";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  & > .wrapper {
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid var(--iq-gray-300);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;

    & > .md-title {
      font-weight: bold;
    }
  }
}

.md-icon {
  margin: initial;
}

.w-30 {
  width: 30%;
}
</style>
